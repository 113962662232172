import { createSelector } from '@reduxjs/toolkit';
import { addDays, startOfDay } from 'date-fns';
import { sortBy } from 'lodash-es';
import { selectSourceCustomersAllMap } from 'services/customers';
import { NOTE_CUSTOMER } from 'services/notes';
import { convertToDate, getCalendarMonthBounds, isAcrossIntervals } from 'utils/dates';
import { getSearchFilter } from 'utils/front-filters';
import { selectAccountUser } from '../auth';
import { AppState } from '../index';
import { isNoteCustomerID, selectNotesLayoutFilters } from '../notes-layout';
import { notesDueDateToRange, NOTES_DUE_DATE, NOTES_VIEW } from './helpers';

const selectState = (state: AppState) => state.notes;

const selectData = createSelector(selectState, ({ data }) => data);

export const selectView = createSelector(selectState, ({ view }) => view);
export const selectArchive = createSelector(selectState, ({ archive }) => archive);
export const selectAttachToTicket = createSelector(
  selectState,
  ({ attachToTicket }) => attachToTicket,
);

export const selectGrid = createSelector(selectState, ({ [NOTES_VIEW.GRID]: data }) => data);
export const selectGridFilters = createSelector(selectGrid, ({ filters }) => filters);
export const selectGridOrders = createSelector(selectGrid, ({ orders }) => orders);
export const selectGridSizes = createSelector(selectGrid, ({ sizes }) => sizes);

export const selectTimeLine = createSelector(
  selectState,
  ({ [NOTES_VIEW.TIMELINE]: data }) => data,
);
export const selectTimeLineFilters = createSelector(selectTimeLine, ({ filters }) => filters);

export const selectCalendar = createSelector(
  selectState,
  ({ [NOTES_VIEW.CALENDAR]: data }) => data,
);
export const selectCalendarFilters = createSelector(selectCalendar, ({ filters }) => filters);

export const selectNotesStatuses = createSelector(
  selectState,
  ({ isLoading, isMounted, isOpenExpiredManager }) => {
    return { isLoading, isMounted, isOpenExpiredManager };
  },
);

export const selectDataFull = createSelector(
  selectData,
  selectSourceCustomersAllMap,
  selectAccountUser,
  (rows, mapCustomer, user) => {
    return rows.map((row) => {
      return {
        ...row,
        _customer: row.customerID ? mapCustomer[row.customerID] : null,
        _archiveDate: addDays(convertToDate(row.dueDate), user?.noteFadeDefaultDuration || 0),
      };
    });
  },
);

export const selectDataExpired = createSelector(selectDataFull, (rows) => {
  return rows.filter((row) => {
    return startOfDay(new Date()) > startOfDay(convertToDate(row.dueDate));
  });
});

export const selectDataFullFilteredForGrid = createSelector(
  selectDataFull,
  selectGridFilters,
  selectNotesLayoutFilters,
  (rows, filters, layoutFilters) => {
    const dueDateRange = notesDueDateToRange(filters.dates);
    const today = startOfDay(new Date());

    return rows.filter((row) => {
      let res = [true];

      if (layoutFilters.customer) {
        if (layoutFilters.customer === NOTE_CUSTOMER.WITHOUT_CUSTOMER) {
          res.push(!row.customerID);
        }
        if (isNoteCustomerID(layoutFilters.customer)) {
          res.push(row.customerID === layoutFilters.customer);
        }
      }

      if (filters.search) {
        const _row = {
          title: row.title,
          description: row.description,
          customer: row._customer?.title,
        };
        res.push(
          getSearchFilter<typeof _row>({
            value: filters.search,
            fields: ['title', 'customer', 'description'],
          })(_row),
        );
      }

      if (filters.dates === NOTES_DUE_DATE.PAST_DUE_DATE) {
        res.push(today > startOfDay(convertToDate(row.dueDate)));
      } else if (row.entryDate && row.dueDate && dueDateRange.length === 2) {
        res.push(isAcrossIntervals([row.entryDate, row.dueDate], dueDateRange));
      }

      return res.every(Boolean);
    });
  },
);

export const selectGridRows = createSelector(
  selectDataFullFilteredForGrid,
  selectGridOrders,
  selectGridSizes,
  (rows, orders, sizes) => {
    return rows
      .map((item) => {
        return {
          ...item,
          _orderIndex: orders[item.id] || item.rowIndex || 0,
          _size: sizes[item.id] || { width: 0, height: 0 },
        };
      })
      .sort((a, b) => a._orderIndex - b._orderIndex);
  },
);

export const selectDataFullFilteredForCalendar = createSelector(
  selectDataFull,
  selectCalendarFilters,
  selectNotesLayoutFilters,
  (rows, filters, layoutFilters) => {
    const adjustedDates = getCalendarMonthBounds(filters.dates[0]);
    return rows.filter((row) => {
      let res = [true];

      if (layoutFilters.customer) {
        if (layoutFilters.customer === NOTE_CUSTOMER.WITHOUT_CUSTOMER) {
          res.push(!row.customerID);
        }
        if (isNoteCustomerID(layoutFilters.customer)) {
          res.push(row.customerID === layoutFilters.customer);
        }
      }

      if (filters.search) {
        const _row = {
          title: row.title,
          description: row.description,
          customer: row._customer?.title,
        };
        res.push(
          getSearchFilter<typeof _row>({
            value: filters.search,
            fields: ['title', 'customer', 'description'],
          })(_row),
        );
      }

      if (row.entryDate && row.dueDate) {
        res.push(isAcrossIntervals([row.entryDate, row.dueDate], adjustedDates));
      }

      return res.every(Boolean);
    });
  },
);

export const selectDataFullFilteredForCalendarToday = createSelector(
  selectDataFull,
  selectCalendarFilters,
  selectNotesLayoutFilters,
  (rows, filters, layoutFilters) => {
    const adjustedDates = [new Date(), new Date()];
    return rows.filter((row) => {
      let res = [true];

      if (layoutFilters.customer) {
        if (layoutFilters.customer === NOTE_CUSTOMER.WITHOUT_CUSTOMER) {
          res.push(!row.customerID);
        }
        if (isNoteCustomerID(layoutFilters.customer)) {
          res.push(row.customerID === layoutFilters.customer);
        }
      }

      if (filters.search) {
        const _row = {
          title: row.title,
          description: row.description,
          customer: row._customer?.title,
        };
        res.push(
          getSearchFilter<typeof _row>({
            value: filters.search,
            fields: ['title', 'customer', 'description'],
          })(_row),
        );
      }

      res.push(
        Boolean(
          row.entryDate &&
            row.dueDate &&
            isAcrossIntervals([row.entryDate, row.dueDate], adjustedDates),
        ),
      );

      return res.every(Boolean);
    });
  },
);

export const selectDataFullFilteredForTimeLine = createSelector(
  selectDataFull,
  selectTimeLineFilters,
  selectNotesLayoutFilters,
  (rows, filters, layoutFilters) => {
    return sortBy(
      rows.filter((row) => {
        let res = [true];

        if (layoutFilters.customer) {
          if (layoutFilters.customer === NOTE_CUSTOMER.WITHOUT_CUSTOMER) {
            res.push(!row.customerID);
          }
          if (isNoteCustomerID(layoutFilters.customer)) {
            res.push(row.customerID === layoutFilters.customer);
          }
        }

        if (filters.search) {
          const _row = {
            title: row.title,
            description: row.description,
            customer: row._customer?.title,
          };
          res.push(
            getSearchFilter<typeof _row>({
              value: filters.search,
              fields: ['title', 'customer', 'description'],
            })(_row),
          );
        }

        if (row.entryDate && row.dueDate) {
          res.push(isAcrossIntervals([row.entryDate, row.dueDate], filters.dates));
        }

        return res.every(Boolean);
      }),
      ['dueDate'],
    );
  },
);

import EditIcon from '@mui/icons-material/Edit';
import { Box, Popper, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { useOpen, useTranslate } from 'hooks';
import React, { useRef } from 'react';
import { composeFunctions } from 'utils/other';
import { IDataGridEditorContainerProps } from '../../models';
import style from './index.module.scss';

interface Props extends IDataGridEditorContainerProps {}
export const DataGridEditorContainer: React.FC<Props> = ({
  children,
  row,
  field,
  Editor,
  EditorProps,
}) => {
  const { isOpen, onOpen, onClose } = useOpen();
  const { isOpen: isEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useOpen();
  const ref = useRef<HTMLElement>(null);
  const { tp } = useTranslate();
  return (
    <>
      {isEdit && <Editor row={row} field={field} onClose={onCloseEdit} EditorProps={EditorProps} />}
      {!isEdit && (
        <>
          {children}
          <Box
            className={clsx(style.root, isOpen && style.hover)}
            ref={ref}
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            onClick={(e) => {
              e.stopPropagation();
              composeFunctions(onOpenEdit, onClose)();
            }}
          >
            <Popper open={isOpen} anchorEl={ref.current} placement={'bottom-end'} disablePortal>
              <Stack spacing={0.6} direction={'row'} textAlign={'right'} className={style.helper}>
                <Typography variant={'caption'}>{tp('grid-edit-cell-helper')}</Typography>
                <EditIcon fontSize={'inherit'} sx={{ fontSize: 12 }} />
              </Stack>
            </Popper>
          </Box>
        </>
      )}
    </>
  );
};
